/*
*
* RD Google Map
*/

.gm-style-pbt {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: $gray-900;
	color: $white;
	padding: 5px 14px;
	font-size: 16px;
	border-radius: 3px;
	box-shadow: $shadow-area-2;
	width: 100%;
	text-align: center;
	max-width: 300px;
}

/*
* @subsection Google Map
* @description Describes style declarations for RD Google Map extension
* @version     1.0.0
*/
$map-height: 200px;
$map-xs-height: 250px;
$map-sm-height: 400px;
$map-lg-height: 450px;
$map-xl-height: 520px;

.google-map-markers{
	display: none;
}

.google-map-container {
	width: 100%;
}
.google-map {
	height: $map-height;
	width: 100%;

	@include media-breakpoint-up(sm) {
		height: $map-xs-height;
	}

	@include media-breakpoint-up(md) {
		height: $map-md-height;
	}

	@include media-breakpoint-up(lg) {
		height: $map-lg-height;
	}

	@include media-breakpoint-up(xl) {
		height: $map-xl-height;
	}
}

.gm-style-iw > div > div {
	padding-right: 10px;
	font-size: 13px;
}

// Vertical align
.google-map-align {
	display: flex;
	min-height: $map-height;
	height: auto;

	.google-map { height: auto; }
}