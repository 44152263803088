/*
*
* Owl Carousel
*/

.owl-carousel .animated {
	animation-duration: 1000ms;
	animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
	z-index: 0;
}

.owl-carousel .owl-animated-out {
	z-index: 1;
}

.owl-carousel .fadeOut {
	animation-name: fadeOut;
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
	transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	/* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;
}

.owl-carousel .owl-stage {
	position: relative;
	-ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.owl-carousel .owl-stage-outer {
	position: relative;
	overflow: hidden;
	/* fix for flashing background */
	-webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
	cursor: pointer;
	user-select: none;
}

.owl-carousel.owl-loaded {
	display: block;
}

.owl-carousel.owl-loading {
	opacity: 0;
	display: block;
}

.owl-carousel.owl-hidden {
	opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
	display: none;
}

.owl-carousel .owl-item {
	position: relative;
	min-height: 1px;
	float: left;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}

.owl-carousel .owl-grab {
	cursor: move;
	cursor: -webkit-grab;
	cursor: grab;
}

.owl-carousel.owl-rtl {
	direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
	float: right;
}

/* No Js */
.no-js .owl-carousel {
	display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
	opacity: 0;
	transition: opacity 400ms ease;
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
	position: relative;
	height: 100%;
	background: #000000;
}

.owl-carousel .owl-video-play-icon {
	position: absolute;
	height: 80px;
	width: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	font: 400 40px/80px 'FontAwesome';
	cursor: pointer;
	z-index: 1;
	transition: scale 100ms ease;
	&:before {
		content: '\f144';
	}
}

.owl-carousel .owl-video-play-icon:hover {
	transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
	display: none;
}

.owl-carousel .owl-video-tn {
	opacity: 0;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
	position: relative;
	z-index: 1;
}

/*
 * Owl Navigation
 */

.owl-nav {
	&.disabled {
		display: none !important;
	}
}

.owl-prev,
.owl-next {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	font: 400 46px/50px 'FontAwesome';
	color: $gray-300;

	&:hover {
		color: $primary;
	}
}

.owl-prev {
	left: 0;

	&::before {
		content: '\f104';
	}
}

.owl-next {
	right: 0;

	&::before {
		content: '\f105';
	}
}

/*
 * Owl Pagination
 */

.owl-dots {
	margin-top: 10px;

	&.disabled {
		display: none !important;
	}
	
	@include media-breakpoint-up(sm) {
		margin-top: 25px; 
	}
}

button.owl-dot {
	@include reset-button;
}

.owl-dot {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	width: 14px;
	height: 14px;
	border-radius: 50%;
	margin-left: 14px;
	margin-right: 14px;
	text-align: center;
	outline: none;
	cursor: pointer;
	
	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		transform: scale3d(.56, .56, .56);
		background: $gray-200;
		border-radius: inherit;
		transition: .2s;
	}
	
	&:only-child { display: none; }

	&:hover,
	&.active {
		&::before {
			transform: scale3d(1, 1, 1);
			background-color: $primary;
		}
	}
}

* + .owl-carousel { margin-top: 30px; }


// Custom
.owl-carousel-1 {
	text-align: center;

	.owl-stage {
		position: relative;
		display: flex;
		align-items: center;
	}
}
 
.owl-carousel-centered-pagination {
	.owl-dots {
		text-align: center;
	}
}


.owl-carousel-indigo {
	position: relative;
	overflow: hidden;
	max-width: 100vw;
	padding: 0 15px;
	margin-left: auto;
	margin-right: auto;
	pointer-events: none;
	
	&, * {
		backface-visibility: hidden;
	}
	
	.owl-stage-outer {
		padding: 23px;
		margin: -23px; 
	}
	
	.owl-item { 
		&.active {
			pointer-events: auto;
		}
	} 
	
	.owl-dots {
		pointer-events: auto;
	}

	* + .owl-dots { margin-top: 25px; }
	
	@include media-breakpoint-down(xs) {
	   .owl-dot {
			 width: 11px;
			 height: 11px;
			 margin: 0 6px; 
		 }
	}
	
	@include media-breakpoint-up(md) {
		padding: 0 0;
	  * + .owl-dots { margin-top: 60px; } 
	}
}


.owl-carousel-dots-space {
	.owl-dots {
		margin-bottom: 25px;
	}
}
