/*
*
* Insets
*/

// 
// Left
//
.inset-left-1 {
	@include media-breakpoint-up(sm) {
		padding-left: 10px; 
	}
	
	@include media-breakpoint-up(md) {
		padding-left: 30px; 
	}
	 
	@include media-breakpoint-up(lg) {
		padding-left: 0;
	}
}

.inset-left-2 {
	@include media-breakpoint-between(lg, lg) {
		padding-left: 30px;
	}
}

//
// Right 
//
.inset-right-1 {
	@include media-breakpoint-up(sm) {
		padding-right: 20px;
	}

	@include media-breakpoint-up(md) {
		padding-right: 30px;
	}

	@include media-breakpoint-between(lg, lg) {
		padding-right: 0;
	}
}

.inset-right-2 {
	@include media-breakpoint-up(lg) {
		padding-right: 30px;
	}

	@include media-breakpoint-up(xl) {
		padding-right: 0;
	}
}

.inset-right-3 {
	@include media-breakpoint-up(lg) {
		padding-left: 5px;
	}

	@include media-breakpoint-up(xl) {
		padding-right: 0;
	}
}


