/*
*
* Quotes
*/

//
// Table of Contents: 
//
// Quote Light
// Quote Classic
// Quote Modern
//

//
// Quote Light
//
.quote-light {
}

.quote-light-mark {
	fill: $gray-300;
}

.quote-light-cite {
	font-size: 20px;
	color: $primary;
}

.quote-light-caption {
	line-height: 1.2;
}

.quote-light-text {
	font-size: 16px;
	line-height: 1.5;
	letter-spacing: 0;
	color: $gray-700;
}

html img.quote-light-image {
	display: inline-block;
}

* + .quote-light { margin-top: 30px; }
* + .quote-light-caption { margin-top: 3px; }
* + .quote-light-mark { margin-top: 15px; }
* + .quote-light-text { margin-top: 8px; }
* + .quote-light-footer { margin-top: 20px; }

@include media-breakpoint-up(sm) {
	.quote-light-cite {
		font-size: 20px;
	}
}

@include media-breakpoint-up(md) {
	.quote-light { }

	.quote-light-cite {
		font-size: 24px;
	}

	.quote-light-text {
		font-size: 21px;
		line-height: 1.45;
	}

	* + .quote-light { margin-top: 40px; }
	* + .quote-light-mark { margin-top: 35px; }
	* + .quote-light-text { margin-top: 15px; }
	* + .quote-light-footer { margin-top: 30px; }
}

@include media-breakpoint-up(xxl) {

	.quote-light-text {
		font-size: 24px;
		line-height: (32 / 24);
	}

	* + .quote-light { margin-top: 50px; }
	* + .quote-light-mark { margin-top: 50px; }
	* + .quote-light-footer { margin-top: 45px; }
}

//
// Quote Classic
//
.quote-classic {
	max-width: 340px;
	text-align: left;
}

.quote-classic-meta {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	@include group(20px);
}

.quote-classic-avatar {
	position: relative;
	overflow: hidden;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	flex-shrink: 0;
	
	img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		min-height: 100%;
		min-width: 100%;
		width: auto;
		height: auto;
		max-width: none;

		@supports (object-fit: cover) {
			left: 0;
			top: 0; 
			width: 100%;
			height: 100%;
			transform: none;
			object-fit: cover;
			object-position: center center;
		}
	}
}

.quote-classic-cite {
	font-size: 20px;
	line-height: 1.2;
	color: $primary;
}

.quote-classic-caption {
	color: $gray-300;
}

* + .quote-classic-text { margin-top: 25px; }
* + .quote-classic-caption { margin-top: 0; }

@include media-breakpoint-down(xs) {
	.quote-classic {
		margin-left: auto;
		margin-right: auto;
	}
}


// 
// Quote Modern
// 
.quote-modern {
	text-align: left;
}

.quote-modern-meta {
	display: flex; 
	align-items: center;
	flex-wrap: wrap;
	@include group(20px);
}

.quote-modern-avatar {
	position: relative;
	overflow: hidden;
	z-index: 2;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	flex-shrink: 0;

	img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		min-height: 100%;
		min-width: 100%;
		width: auto; 
		height: auto;
		max-width: none;

		@supports (object-fit: cover) { 
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			transform: none;
			object-fit: cover;
			object-position: center center;
		}
	}
}

.quote-modern-cite {
	font-size: 20px;
	line-height: 1.2;
	color: $primary;
}

.quote-modern-caption {
	color: $gray-300;
}

.quote-modern-mark {
	fill: $gray-300;
}


* + .quote-modern-meta { margin-top: 25px; }
* + .quote-modern-caption { margin-top: 0; }
* + .quote-modern-text { margin-top: 10px; }

// Mods
.quote-modern-big {
	.quote-modern-text {
		font-size: 18px;
		line-height: 1.5;
		color: $gray-700;
	}
	
	@include media-breakpoint-up(lg) {
		.quote-modern-text {
			font-size: 20px;
		}
	}
	
	@include media-breakpoint-up(xl) {
		.quote-modern-text {
			font-size: 24px;
			line-height: (32 / 24);
		}  
		
		* + .quote-modern-text { margin-top: 20px; }
	}
}
