/*
*
* Pagination custom
*/

.pagination {
	font-size: 0;
	line-height: 0;
	@include group(10px);
}

.page-item {
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	font-weight: 700;
}

// Base styles
.page-link {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: $form-input-height;
	min-height: $form-input-height;
	padding: $pagination-padding-y $pagination-padding-x;
	border: 0;
	border-radius: $border-radius-lg;
	font-family: $font-family-sec;
	font-size: $pagination-font-size;
	font-weight: 500;
	line-height: ($pagination-line-height / $pagination-font-size);
	background-color: $pagination-bg;
	color: $pagination-color;
	transition: $transition-base;
} 

.page-link.button-winona {
	* {
		line-height: inherit;
	}
	
	.content-dubbed {
		margin-top: -1px;
	}
}

// Hover & Focus & Active State
.page-link {
	&:hover,
	&:focus,
	&:active {
		color: $pagination-hover-color;
		background-color: $pagination-hover-bg;
	}
}

// Active State
.page-item.active > .page-link {
	&,
	&:hover,
	&:focus,
	&:active {
		color: $pagination-active-color;
		background-color: $pagination-active-bg;
	}
}

// Disabled State
.page-item.disabled > .page-link {
	&,
	&:hover,
	&:focus,
	&:active {
		color: $pagination-disabled-color;
		background-color: $pagination-disabled-bg;
	}
}

.page-item-control {
	.icon::before {
		font-family: 'FontAwesome';
		font-size: 11px;
		line-height: $pagination-line-height;
	}
}

// Prev control
.page-item-control:first-child {
	.icon::before {
		content: '\f053';
		margin-left: -1px;
	}
}

// Next control
.page-item-control:last-child {
	.icon::before {
		content: '\f054';
		margin-right: -1px;
	}
}

* + .pagination { margin-top: 35px; }

@include media-breakpoint-up(lg) {
	* + .pagination { margin-top: 55px; } 
}

@include media-breakpoint-up(xxl) {
	* + .pagination { margin-top: 70px; } 
} 
