/*
* Trunk version 2.0.0
*/


//
// Main styles
//
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/mixins";
@import "color-schemes/colors-2"; 
@import "variables-custom";
@import "mixins";
@import "reset";

@import "main-styles"; 
//@import "plugins/layout-panel";
