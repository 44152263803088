/*
*
* Icons
*/

//
// Base styles
//
.icon {
	display: inline-block;
	font-size: 16px;
	line-height: 1;

	&::before {
		position: relative;
		display: inline-block;
		font-weight: 400;
		font-style: normal;
		speak: none;
		text-transform: none;
	}
}

//
// Icon Styles
//

.icon-creative {
	display: inline-block;
	text-align: center;
	width: 2em;
	height: 2em;
	line-height: 2em;
	border-radius: 50%;
	&, 
	&:active, 
	&:focus {
		color: $gray-300;
	}
	
	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		border-radius: inherit;
		pointer-events: none;
		opacity: 0;
		transform: scale3d(0, 0, 0);
		transition: .22s;
	}
	
	&:hover {
		color: $white;
		&::after {
			opacity: 1;
			transform: scale3d(1, 1, 1);
		}
	}
	
	&[class*='facebook'] {
		&::after { background: $facebook; }
	}
	
	&[class*='twitter'] {
		&::after { background: $twitter; }
	}
	
	&[class*='instagram'] {
		&::after { background: $instagram; }
	}
	
	&[class*='google'] {
		&::after { background: $google; }
	}
	
	&[class*='linkedin'] {
		&::after { background: $linkedin; }
	}
}

.icon-modern {
	width: 2em;
	height: 2em;
	line-height: 2em;
	text-align: center;
	border-radius: 50%;
	border: 1px solid $gray-200;
	color: $primary;
}

//
// Icon Shapes
//
.icon-circle {
	border-radius: 50%;
}

//
// Icon Sizes
//
.icon-xs {
}

.icon-sm {
	font-size: 20px;
}

.icon-md {
	font-size: 1.5em; // 24
}

.icon-lg {
	font-size: 1.875em; // 30
}
